import { BidiModule } from '@angular/cdk/bidi';
import { NgFor } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { OrderModel } from 'src/app/core/domain/order-model';
import { VariantModel } from 'src/app/core/domain/variant-group.model';
import { OrderIssuesService } from 'src/app/presentation/shared/services/orderIssues.service';
import { getSizedImage, ImageSize } from '../../shared/utilities/get-sized-image.utility';

@Component({
  selector: 'app-order-replacements',
  templateUrl: './order-replacements.component.html',
  styleUrls: ['./order-replacements.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    MatTabsModule,
    BidiModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatInputModule,
    MatButtonModule,
    TranslateModule,
  ],
})
export class OrderReplacementsComponent implements OnInit {
  public products: any[] = [];

  public product = {
    productObjectId: String,
    productQty: Number,
    productProfit: Number,
    productId: Number,
  };

  public replaceOrderForm: UntypedFormGroup;

  public sameProductReplacement = true;

  public selectedIndex = 0;

  public clicked = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { order: OrderModel },
    private toastr: ToastrService,
    private orderIssuesService: OrderIssuesService,
    private dialogRef: MatDialogRef<OrderReplacementsComponent>,
    private _translateService: TranslateService,
  ) {
    this.replaceOrderForm = new UntypedFormGroup({
      product: new UntypedFormControl(),
      productQty: new UntypedFormControl(),
      issueReason: new UntypedFormControl(),
      phoneNum: new UntypedFormControl(),
      notes: new UntypedFormControl(),
      issueImage: new UntypedFormControl(),
      issueVideo: new UntypedFormControl(),
    });
    dialogRef.disableClose = false;
  }

  ngOnInit(): void {
    this.products = this.data.order.orderLines.map(
      (orderLine) =>
        ({
          productName: `${orderLine.productName}${
            orderLine.productColor ? `, ${orderLine.productColor}` : ''
          }${orderLine.productSize ? `, ${orderLine.productSize}` : ''}`,
          productQuantity: orderLine.quantity,
          productProfit: orderLine.totalMerchantProfit,
          prodID: orderLine.productId,
          productPicture: getSizedImage(orderLine.productPicture!, ImageSize.small),
        } as VariantModel),
    );
  }

  addQuantity(): void {
    if (this.replaceOrderForm.value.productQty < this.product.productQty) {
      this.replaceOrderForm.get('productQty')!.setValue(this.replaceOrderForm.value.productQty + 1);
    }
  }

  removeQuantity(): void {
    if (this.replaceOrderForm.value.productQty > 1) {
      this.replaceOrderForm.get('productQty')!.setValue(this.replaceOrderForm.value.productQty - 1);
    }
  }

  onProductChange(event: any): void {
    this.product = {
      productObjectId: event.value._id,
      productQty: event.value.productQuantity,
      productProfit: event.value.productProfit,
      productId: event.value.prodID,
    };
    this.replaceOrderForm.get('productQty')!.setValue(event.value.productQuantity);
  }

  public OpenFile(video: any): void {
    if (video) {
      document.getElementById('formControlVideo')!.click();
    } else {
      document.getElementById('formControlImage')!.click();
    }
  }

  public onSendFile(event: any, video: any): void {
    this.clicked = true;
    this.toastr.info('Uploading file');
    const {
      target: { name, files },
    } = event;
    const c = 0;
    const formData = new FormData();
    formData.append('image', files[0]);
    const filesize = (files[0].size / 1024 / 1024).toFixed(4); // MB
    if (Number(filesize) < 15) {
      this.orderIssuesService.addIssueAttachment(formData).subscribe({
        next: (resp: any) => {
          this.clicked = false;
          this.toastr.success('Uploaded file');
          if (video) {
            this.replaceOrderForm.get('issueVideo')!.setValue(resp.msg);
          } else {
            this.replaceOrderForm.get('issueImage')!.setValue(resp.msg);
          }
        },
        error: (err) => {
          this.toastr.error(err.error.msg);
        },
      });
    } else {
      this.toastr.error(this._translateService.instant('ORDERS_PAGE.FILE_SIZE_ERROR'));
    }
  }

  submit(): number | undefined {
    if (!this.replaceOrderForm.value.product) {
      this.toastr.error(
        this._translateService.instant(
          'ORDERS_PAGE.ORDER_REPLACEMENT_DIALOG.PRODUCT_SELECTION_LABEL',
        ),
      );
      return 0;
    }
    if (this.clicked) {
      this.toastr.info('Uploading file');
      return 0;
    }
    if (this.product.productQty < this.replaceOrderForm.value.productQty) {
      this.toastr.error(
        this._translateService.instant('ORDERS_PAGE.REFUND_PRODUCT.MORE_THAN_ORDER_QUANTITY'),
      );
      return 0;
    }
    this.clicked = true;
    this.product.productQty = this.replaceOrderForm.value.productQty;
    const reqObj = {
      issueType: 2,
      order: {
        orderObjectId: this.data.order._id,
        OrderId: this.data.order.orderID,
      },
      product: this.product,
      issueReason: this.replaceOrderForm.value.issueReason,
      notes: this.replaceOrderForm.value.notes ? this.replaceOrderForm.value.notes : '',
      sameProductReplacement: this.selectedIndex === 0,
      issueImage: this.replaceOrderForm.value.issueImage
        ? this.replaceOrderForm.value.issueImage
        : '',
      issueVideo: this.replaceOrderForm.value.issueVideo
        ? this.replaceOrderForm.value.issueVideo
        : '',
    };

    this.orderIssuesService.addOrderReplacement(reqObj).subscribe({
      next: (res: any) => {
        this.dialogRef.close({ data: 'confirmed' });
        this.toastr.success(
          this._translateService.instant('ORDERS_PAGE.REFUND_PRODUCT.REQUEST_SUCCESS'),
        );
      },
      error: (err) => {
        this.clicked = false;
      },
    });
  }
}
