/* eslint-disable id-blacklist */
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  dateFormat,
  monthNameDateFormat,
  ORDER_STATUSES,
} from 'src/app/presentation/shared/constants';
import { IconsService } from 'src/app/presentation/shared/services/icons.service';
// Ignoring the linting check because this comes from a Kotlin Library
import { Clipboard } from '@angular/cdk/clipboard';
import { DatePipe, NgClass, NgFor, NgIf, registerLocaleData } from '@angular/common';
import localeAr from '@angular/common/locales/ar';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { country } from '@features/country/data';
import { user } from '@features/user/data';
import { LangChangeEvent, TranslateModule, TranslateService } from '@ngx-translate/core';
// @ts-ignore
import { getRejectionReasonByReasonNumberUseCase } from '@taager-experience-shared/rejection-reasons';
import { getSuspensionReasonByReasonNumberUseCase } from '@taager-experience-shared/suspension-reasons';
import { ToastrService } from 'ngx-toastr';
import { finalize, Subscription } from 'rxjs';
import { OrderActivityWithStatusModel } from 'src/app/core/domain/order/order-activity-with-status.model';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { GetFeatureAttributeUsecase } from 'src/app/core/usecases/get-feature-attribute.usecase';
import { GetOrderActivityWithStatusUseCase } from 'src/app/core/usecases/order/order-activity-with-status.usecase';
import { TrackOrderActivityUseCase } from 'src/app/core/usecases/order/track-order-activity.usecase';
import { LoaderComponent } from '../../shared/components/loader/loader.component';
import { ENGLISH_LANGUAGE } from '../../shared/constants/country-language-codes-mapping.constants';
import { WEB_ORDERS_REVAMP } from '../../shared/constants/feature-flags';
import { SUSPENDED_STATUS } from '../../shared/constants/order-statuses';
import { SiteTranslateService } from '../../shared/services/translate.service';
import { featureAttributeAssign } from '../../shared/utilities/feature-attribute-assign.utility';
import { OrderInfo, OrderStatus } from './track-orders.interfaces';

registerLocaleData(localeAr, 'ar');
@Component({
  selector: 'app-order-tracking-dialog',
  templateUrl: './track-orders-dialog.component.html',
  styleUrls: ['./track-orders-dialog.component.scss'],
  standalone: true,
  imports: [
    MatDialogModule,
    NgIf,
    NgFor,
    MatIconModule,
    NgClass,
    DatePipe,
    TranslateModule,
    LoaderComponent,
    MatExpansionModule,
  ],
})
export class OrderTrackingDialogComponent implements OnInit {
  order: OrderInfo;

  orderProgress = 0;

  orderStatuses: OrderStatus[] = [];

  dateFormat = dateFormat;

  dateTimeFormat = monthNameDateFormat;

  public isEnglishLanguage = false;

  public isLoading = false;

  private _languageChangeSubscription: Subscription;

  public trackingLinkExist: boolean;

  public shouldEnableOrdersRevamp = false;

  public isFeedbackProvided = false;

  public suspendedStatus = SUSPENDED_STATUS;

  constructor(
    private dialogRef: MatDialogRef<OrderTrackingDialogComponent>,
    private _getOrderActivityWithStatusUseCase: GetOrderActivityWithStatusUseCase,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private iconsService: IconsService,
    private _siteTranslateService: SiteTranslateService,
    private _translateService: TranslateService,
    private _clipboard: Clipboard,
    private _toast: ToastrService,
    private _getFeatureAttributeUseCase: GetFeatureAttributeUsecase,
    private _trackOrderActivityUseCase: TrackOrderActivityUseCase,
    private _logMixpanelEventUseCase: LogMixpanelEventUseCase,
  ) {
    if (this.data.order.orderID) {
      this.data.order.orderId = this.data.order.orderID;
    }
    this.order = this.createOrderObject(this.data.order);
    this.isEnglishLanguage = this._siteTranslateService.getCurrentLanguage() === ENGLISH_LANGUAGE;
    this._languageChangeSubscription = this._translateService.onLangChange.subscribe({
      next: (event: LangChangeEvent) => {
        this.isEnglishLanguage = event.lang === ENGLISH_LANGUAGE;
      },
    });
  }

  ngOnInit(): void {
    this.getOrdersRevampStatus();
    this.registerIcons();
    this.getOrderActivity();
  }

  public getOrdersRevampStatus(): void {
    this._getFeatureAttributeUseCase.execute(WEB_ORDERS_REVAMP).subscribe({
      next: (status) => {
        this.shouldEnableOrdersRevamp = featureAttributeAssign(status, user.id, country.code);
      },
    });
  }

  registerIcons(): void {
    const icons = [
      'inbox',
      'clipboard',
      'checked',
      'checkmark',
      'canceled',
      'clock',
      'clock-half-dotted',
      'delivery-truck',
      'fast-delivery',
      'shipped',
      'refund-request',
      'refund-inprogress',
      'replacement-request',
      'replacement-inprogress',
      'completion-request',
      'completion-inprogress',
      'pause',
    ].map((icon) => {
      return this.iconsService.addIconToRegistry(
        icon,
        `../assets/img/track-order-icons/${icon}.svg`,
      );
    });
  }

  getOrderActivity(): void {
    this.isLoading = true;
    const filter: OrderActivityWithStatusModel = {
      filterObj: { orderID: this.order.id },
    };
    if (this.shouldEnableOrdersRevamp) {
      this._trackOrderActivityUseCase
        .execute(filter)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: (res) => {
            this.orderProgress = res.data.length;
            let tempStatuses = res.data.map((item) => item);
            if (
              this.orderProgress < 5 &&
              tempStatuses.filter((r) => this.checkCanceledOrSuspended(r.status)).length === 0
            ) {
              tempStatuses = this.appedDefaultStatuses(tempStatuses);
            }
            this.orderStatuses = this.createOrderStatusesArray(tempStatuses);
          },
        });
    } else {
      this._getOrderActivityWithStatusUseCase
        .execute(filter)
        .pipe(
          finalize(() => {
            this.isLoading = false;
          }),
        )
        .subscribe({
          next: (res) => {
            this.orderProgress = res.data.length;
            // the API embeds the status object inside a doc object incase the user wasn't an admin
            let tempStatuses = res.data.map((item) => item.doc);
            if (
              this.orderProgress < 5 &&
              tempStatuses.filter((r) => this.checkCanceledOrSuspended(r.orderStatus)).length === 0
            ) {
              tempStatuses = this.appedDefaultStatuses(tempStatuses);
            }
            this.orderStatuses = this.createOrderStatusesArray(tempStatuses);
          },
        });
    }
  }

  createOrderObject(order: any): OrderInfo {
    return {
      id: order.orderId,
      createdAt: order.createdAt,
      confirmationDate: order.confirmationDate,
      deliveryDate: order.deliveryDate,
    };
  }

  public copyTrackingLink(): void {
    const { trackingLink } = this.orderStatuses[0];
    this._clipboard.copy(trackingLink!);
    this._toast.info('Tracking Link Copied');
  }

  public openTrackingLink(): void {
    this._logMixpanelEventUseCase.execute({ eventName: 'Track_shipment' });
    const { trackingLink } = this.orderStatuses[0];
    window.open(trackingLink, '_blank');
  }

  appedDefaultStatuses(tempStatuses: any): any {
    for (let i = this.orderProgress; i < 5; i++) {
      tempStatuses.push({
        status: ORDER_STATUSES.DIRECT_FLOW_STATUSES_ARRAY[i],
        updatedAt: '',
      });
    }
    return tempStatuses;
  }

  createOrderStatusesArray(orderStatuses: any): OrderStatus[] {
    const orderStatusesArray: OrderStatus[] = [];
    if (orderStatuses[0].trackingLink) {
      this.trackingLinkExist = true;
    }
    orderStatuses.forEach((status: any) => {
      orderStatusesArray.push({
        icon: this.mapStatustoIcon(status.status),
        statusInEnglish: status.status,
        statusTranslationKey: this.mapStatusEnglishToTranslationKey(status.status),
        canceledOrSuspended: this.checkCanceledOrSuspended(status.status),
        updatedAt: status.updatedAt,
        suspensionReason: this.getSuspensionReasonText(status.detailedStatus?.reason?.number),
        rejectionReason: this.getRejectionReasonText(status.detailedStatus?.reason?.number),
        notes: status.notes,
        failedAttemptNote: status.notes,
        deliverySuspendedReason: this.matchDeliverySuspendedReason(
          status.detailedStatus?.reason?.number,
        ),
        code: status.detailedStatus?.reason?.code,
        isEdited: status.isEdited,
        customerStatus: this.isEnglishLanguage
          ? status.detailedStatus?.customerDescription?.en
          : status.detailedStatus?.customerDescription?.ar,
        trackingLink: status.trackingLink,
        reason: this.isEnglishLanguage
          ? status.detailedStatus?.reason?.englishText
          : status.detailedStatus?.reason?.arabicText,
        failedAttempts: status.detailedStatus?.failedAttempts,
      });
    });
    return orderStatusesArray;
  }

  getSuspensionReasonText(suspensionReason: number): string {
    const reason = this.isEnglishLanguage
      ? getSuspensionReasonByReasonNumberUseCase(suspensionReason).englishText
      : getSuspensionReasonByReasonNumberUseCase(suspensionReason).arabicText;
    return reason;
  }

  getRejectionReasonText(rejectionReason: number): string {
    const reason = this.isEnglishLanguage
      ? getRejectionReasonByReasonNumberUseCase(rejectionReason).englishText
      : getRejectionReasonByReasonNumberUseCase(rejectionReason).arabicText;
    return reason;
  }

  matchDeliverySuspendedReason(reason: string): string {
    return ORDER_STATUSES.DELIVERY_SUSPENSION_REASONS_TRANSLATION[reason] || 'لا يوجد حالياً';
  }

  mapStatustoIcon(recievedStatus: string): string {
    const statusObject = ORDER_STATUSES.ALL_STATUSES.filter(
      (status) => status.statusInEnglish === recievedStatus,
    );
    return statusObject.length === 0 ? '' : statusObject[0].statusIcon;
  }

  mapStatusEnglishToTranslationKey(recievedStatus: string): string {
    const statusObject = ORDER_STATUSES.ALL_STATUSES.filter(
      (status) => status.statusInEnglish === recievedStatus,
    );
    return statusObject.length === 0 ? recievedStatus : statusObject[0].statusTranslationKey;
  }

  checkCanceledOrSuspended(status: string): boolean {
    return ORDER_STATUSES.SUSPENDED_CANCELED_STATUSES_ARRAY.includes(status);
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  shareOrderFeedback(response: boolean): void {
    this.isLoading = true;
    this._logMixpanelEventUseCase.execute({
      eventName: 'ORDER_FEEDBACK',
      payload: {
        orderStatus: this.data?.order?.status,
        orderId: this.data?.order?.orderId,
        feedback: response,
        merchantId: user.id,
      },
    });
    this.isFeedbackProvided = true;
    this._toast.info(
      this.isEnglishLanguage ? 'Feedback shared successfully' : 'لقد تم إرسال إجابتك بنجاح',
    );
    this.isLoading = false;
  }
}
