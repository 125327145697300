<section class="track-orders-dialog">
  <mat-dialog-content>
    <div class="outer-container">
      <p>{{ 'ORDERS_PAGE.ORDER_TRACKING_DIALOG.FOLLOW_UP_ORDER' | translate }}</p>

      <button mat-dialog-close class="btn dialog-close-button col-1 d-sm-none d-lg-block">
        <span>&times;</span>
      </button>
    </div>
    <loader size="md" *ngIf="isLoading" [loading]="isLoading" class="loader"></loader>
    <div class="dialog-container">
      <div class="row align-items-center">
        <h5 class="text-center col-8 dialog-title mb-4">
          {{ 'ORDERS_PAGE.ORDER_TRACKING_DIALOG.TRACK_YOUR_ORDER' | translate }}
        </h5>
        <div class="col-4 track-link" *ngIf="trackingLinkExist">
          <span (click)="openTrackingLink()">{{
            'ORDERS_PAGE.ORDER_TRACKING_DIALOG.TRACK_SHIPMENT' | translate
          }}</span>
          <i (click)="copyTrackingLink()" class="icon icon-copy"></i>
        </div>
      </div>
      <!--header div -->

      <!-- <hr /> -->

      <div *ngIf="!isLoading">
        <div *ngFor="let orderStatus of orderStatuses; let i = index">
          <div class="row">
            <div class="left-div">
              <div
                class="order-status-circle-indicator"
                [ngClass]="{
                  'order-status-circle-indicator__finalState':
                    orderProgress === i + 1 && !orderStatus.canceledOrSuspended,
                  'order-status-circle-indicator__active':
                    orderProgress >= i + 1 && !orderStatus.canceledOrSuspended,
                  'order-status-circle-indicator__warning':
                    orderProgress >= i + 1 &&
                    orderStatus.canceledOrSuspended &&
                    orderStatus.statusInEnglish !== 'suspended',
                  'order-status-circle-indicator__deadState':
                    orderStatus.statusInEnglish === 'suspended'
                }"
              ></div>
              <div
                *ngIf="i < orderStatuses.length - 1"
                [ngClass]="{
                  vertical_dotted_line__active:
                    orderProgress > i + 1 && !orderStatus.canceledOrSuspended
                }"
                class="vertical_dotted_line"
              ></div>
            </div>
            <!--order progress indicator div-->
            <div class="order-status-icon-div">
              <mat-icon
                svgIcon="{{ orderStatus.icon }}"
                class="order-status-icon"
                [ngClass]="{
                  'order-status-icon__active':
                    orderProgress >= i + 1 && !orderStatus.canceledOrSuspended,
                  'order-status-icon__warning':
                    orderProgress >= i + 1 &&
                    orderStatus.canceledOrSuspended &&
                    orderStatus.statusInEnglish !== suspendedStatus
                }"
              >
              </mat-icon>
            </div>
            <!--order status icon div-->
            <div class="col-sm-8 col-lg-10">
              <p
                class="order-status-title caption1--bold"
                [ngClass]="{
                  'order-status-text__warning':
                    orderStatus.canceledOrSuspended && orderStatus.statusInEnglish !== 'suspended',
                  'order-status-title__finalState':
                    orderProgress === i + 1 && !orderStatus.canceledOrSuspended,
                  'order-status-title__active':
                    orderProgress >= i + 1 && !orderStatus.canceledOrSuspended
                }"
              >
                {{ orderStatus.customerStatus || orderStatus.statusTranslationKey | translate }}
              </p>
              <p *ngIf="i === orderProgress" class="caption3--medium">
                {{ 'ORDERS_PAGE.ORDER_TRACKING_DIALOG.HAPPENING_NEXT' | translate }}
              </p>
              <p
                *ngIf="!orderStatus.canceledOrSuspended && orderStatus.updatedAt"
                class="order-status-date"
              >
                {{
                  orderStatus.updatedAt
                    | date : dateTimeFormat : undefined : (isEnglishLanguage ? 'en' : 'ar')
                }}
              </p>
              <mat-accordion
                *ngIf="
                  (orderStatus.statusInEnglish === 'confirmed' && orderStatus.failedAttemptNote) ||
                  orderStatus.canceledOrSuspended
                "
              >
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <mat-panel-title>
                      {{ 'ORDERS_PAGE.ORDER_TRACKING_DIALOG.CONFIRMATION_TRIAL' | translate }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="failed-note">
                    <ng-container
                      *ngIf="i === orderStatuses.length - 1 && orderStatus.failedAttempts"
                    >
                      <div
                        class="failed-attempts"
                        *ngFor="let attempt of orderStatus.failedAttempts"
                      >
                        <p class="caption2--medium">{{ attempt }}</p>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="!orderStatus.canceledOrSuspended; else cancelledNote">
                      <p class="time caption2--medium">
                        {{ orderStatus.updatedAt | date : 'dd MMM, yyyy - hh:MM a' }}
                      </p>
                      <p class="note caption2--medium">{{ orderStatus.failedAttemptNote }}</p>
                    </ng-container>
                    <ng-template #cancelledNote>
                      <div class="cancelled-reason">
                        <mat-icon
                          svgIcon="canceled"
                          class="order-status-icon order-status-icon__warning"
                        >
                        </mat-icon>
                        <div class="reason-information">
                          <p class="time caption2--medium">
                            {{ orderStatus.updatedAt | date : 'dd MMM, yyyy - hh:MM a' }}
                          </p>
                          <p
                            class="text-black caption1--bold"
                            *ngIf="
                              orderStatus.statusInEnglish === 'suspended' ||
                              orderStatus.statusInEnglish === 'customer_rejected'
                            "
                          >
                            {{ orderStatus.reason }}
                          </p>
                          <p class="note caption1--medium">{{ orderStatus.notes }}</p>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
              <p
                *ngIf="orderStatus.statusInEnglish === 'taager_cancelled'"
                class="order-status-text__warning"
              >
                {{ orderStatus.notes }}
              </p>
              <p
                *ngIf="orderStatus.statusInEnglish === 'delivery_suspended'"
                class="order-status-text__warning"
              >
                {{ orderStatus.deliverySuspendedReason }}
              </p>
            </div>
            <div
              class="col-sm-4 col-lg-2"
              *ngIf="orderStatus.statusInEnglish === 'confirmed' && orderStatus.isEdited"
            >
              <div class="status-edit text-center">
                <p class="caption1--bold">
                  {{ 'ORDERS_PAGE.ORDER_TRACKING_DIALOG.ORDER_EDITED' | translate }}
                </p>
              </div>
            </div>
            <!--order status text div-->
          </div>
          <!--row div-->
        </div>
      </div>
      <!--order status div-->
    </div>
    <div class="feedback-container" *ngIf="!isLoading && !isFeedbackProvided">
      <div class="feedback-bg">
        <p class="body1--medium">
          {{ 'ORDERS_PAGE.ORDER_TRACKING_DIALOG.FEEDBACK_QUESTION' | translate }}
        </p>
        <div class="btn-container">
          <button class="primary" (click)="shareOrderFeedback(true)">
            {{ 'ORDERS_PAGE.ORDER_TRACKING_DIALOG.FEEDBACK_YES' | translate }}
          </button>
          <button class="cancel" (click)="shareOrderFeedback(false)">
            {{ 'ORDERS_PAGE.ORDER_TRACKING_DIALOG.FEEDBACK_NO' | translate }}
          </button>
        </div>
      </div>
    </div>
  </mat-dialog-content>
</section>
